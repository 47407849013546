.App {
  text-align: center;
}

.top
{
  width: 100%;
  background-color:  #2c2b42;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.big-video {
  width: 80%; /* Adjust the width as per your layout */
  height: 400px;
  position: absolute;
  bottom: -20px; /* Adjust the distance of the arrow from the bottom */
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.line-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    gap: 40px; /* Adjust the desired spacing between elements */
    position: fixed;
    top: 0;
    right: 0;
    height: 80px;
    width: auto;
    padding: 0 20px; /* Adjust padding as needed */
   
  }

  .short-lines {
    text-align: center;
    column-count: 1; /* Adjust the number of columns as desired */
    column-gap: 20px; /* Adjust the gap between columns as desired */
    width: 60%; /* Adjust the width of the paragraph container as desired */
    color: red;
    font-size: 18px; /* Adjust the font size as desired */
    font-family: 'Arial', sans-serif; /* Adjust the font family as desired */
    line-height: 1.5; /* Adjust the line height as desired */
  }
  
/* ContactSection.css */

.contact-container {
  max-width: 100%;
  height: 400px;
  margin: 0 auto;
  padding: 20px;
 /* background-color: white; /* Updated background color to black */
  /* border: 3px solid white; Updated border color to orange */
  /* border-radius: 5px; */
   /* background-image: url('https://live.staticflickr.com/65535/53063209196_7f2659c057_w.jpg');  */
   background-color:  #2c2b42;

}

.contact-container h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: red; /* Set the text color to white */
  background-color: black; width: 25%;
  
}

.form-group {
  margin-bottom: 20px;
 
}

label {
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
  color: black; /* Set the text color to white */
}

input,
textarea {
  width: 30%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid black; /* Updated border color to orange */
  border-radius: 5px;
  background-color: white; /* Updated background color to black */
  color: black; /* Set the text color to white */
  
}

button[type="submit"] {
  background-color: rgb(91, 190, 66); /* Set the background color to orange */
  color: black;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  width: 10%; /* Set the width to 100% */
  
}

button[type="submit"]:hover {
  background-color: rgb(91, 190, 66); /* Darker shade of orange on hover */
}


.right
{
  float: right; 
}

.right-side-text {
  color: white; /* Set the text color to white */
  text-align: right;
  float: right;
}


.team
{
  width: 100%;
  height: 600px;
  margin: 0 auto;
  background-color:  #2c2b42;
  padding: 20px;
 /* background-color: white; /* Updated background color to black */

  border-radius: 5px;
 
}

.makecontact
{
  text-align: center;
  margin: auto;
  width: 100%;
  height: 300px;
   background-color: white

} 


.white
{
  color: white;
}

.red
{
  color: red;
}


.line-container2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 40px; /* Adjust the desired spacing between elements */
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  width: auto;
  padding: 0 20px; /* Adjust padding as needed */
}

.header {
  padding: 30px;
  text-align: center;
  background: #2c2b42; width: 100%; height: 300px;
  color: white;
  font-size: 30px;
  width: 100%;
}

.line-container3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 40px; /* Adjust the desired spacing between elements */
  position: fixed;
  height: 80px;
  padding: 0 20px; /* Adjust padding as needed */
}


.pic
{
  display: flex;
  align-items: center;
  justify-content: center;
}


.arugot
{
  text-align: center;
  margin: auto;
  width: 100%;
  height: 700px;
   background-color: #2c2b42;
} 

.tab
{
  margin-left: auto;
  margin-right: auto;
  /* border: 10px solid rgb(176, 16, 16); */
  text-align: center;
  border-collapse: collapse;
  width: 80%;
  padding: 15px;
 background-color: rgba(23, 22, 22, 0.959);
 overflow-x:auto
}

.tr
{
  /* border: solid 3px blue; */
  background-color:rgba(22, 22, 22, 0.562); width: 10%; height: 100px;
  color: red;
}

.right-top-div {
  position: fixed; /* Use "absolute" instead if you want it to scroll with the page */
  top: 80px; /* Adjust the distance from the top as needed */
  right: 0;
  background-color: #2c2b42;
  color: #fff;
  padding: 10px;
  width: 100%;
  text-align: center;
 
}

.right-top-div a {
  position: relative;
  color: #fff;
  text-decoration: none;
  margin-right: 10px; /* Adjust the spacing between menu items */
}



.map
{
  width: 600px;
  height: 350px;
}


div.gallery {
  border: 1px solid #ccc;
}

div.gallery:hover {
  border: 1px solid #777;
}

div.gallery img {
  width: 100%;
  height: auto;
}

div.desc {
  padding: 15px;
  text-align: center;
}

* {
  box-sizing: border-box;
}

.responsive {
  padding: 0 6px;
  float: left;
  width: 25%;
}


.teampic
{
  width: 300px;
  height: 300px;
}


.fontarugot
{
  color: red;
  font-size: 40px;
}

.fontth
{
  font-size: 30px;
}

.tdback
{
  background-color: white;
  width: 100px;
  height: 50px;
}

.detback
{
  width: 100%;
  background-color:  #2c2b42;
  /* color: rgb(163, 140, 12); */
  color: red;
  text-align: center;
  font-size: 30px;
}

.backh
{
  background-color:  #2c2b42;
  width: 100%;
  height: 500px
}

.teampic2 {
  width: 300px; /* Set the width to 100% of the container's width */
  height: 300px; /* Let the height adjust automatically to maintain aspect ratio */
  
}



@media only screen and ( max-width: 920px) 
{
  .top {
    height: 80px; /* Adjust the height for phone screens */
  }

  .line-container {
    justify-content: center; /* Center the items on phone screens */
    background-color: #2c2b42; /* Change background color for better visibility on phone screens */
    height: 80px; /* Adjust the height for phone screens */
  }
  .line-container2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px; /* Adjust the desired spacing between elements */
    position: fixed;
    top: 0;
    left: 0;
    height: 80px;
    width: auto;
    padding: 0 20px; /* Adjust padding as needed */
  }
  
  .team
  {
    width: 100%;
    height: 600px;
    margin: 0 auto;
    background-color:  #2c2b42;
    padding: 20px;
   /* background-color: white; /* Updated background color to black */
   
    border-radius: 5px;
   
  }


  .map
{
  width: 200px;
  height: 200px;
}


.map2
{
  width: 300px;
  height: 300px;
}

.contact-container {
  max-width: 100%;
  height: 400px;
  margin: 0 auto;
  padding: 10px;
 /* background-color: white; /* Updated background color to black */
  /* border: 3px solid white; Updated border color to orange */
  /* border-radius: 5px; */
   /* background-image: url('https://live.staticflickr.com/65535/53063209196_7f2659c057_w.jpg');  */
   background-color:  #2c2b42;

}

.contact-container h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: red; /* Set the text color to white */
  background-color: black; width: 200px;
  
}

input,
  textarea {
    width: 50%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid black; /* Updated border color to orange */
    border-radius: 5px;
    background-color: white; /* Updated background color to black */
    color: black; /* Set the text color to white */
   
  }
  
  

button[type="submit"] {
  background-color: rgb(91, 190, 66); /* Set the background color to orange */
  color: black;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  width: 40%; /* Set the width to 100% */
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.responsive {
  padding: 0 10px;
  float: left;
  width: 40%;
}


.header {
  
  text-align: center;
  background: #2c2b42; width: 100%; height: 300px;
  color: white;
  font-size: 30px;
  width: 100%;
}


.teampic {
  width: 150px; /* Set the width to 100% of the container's width */
  height: 150px; /* Let the height adjust automatically to maintain aspect ratio */
  
}



.makecontact
{
  text-align: center;
  margin: auto;
  width: 100%;
  height: 300px;
   background-color: white

} 

.li
{
  color: blue;
  font-size: 14px;
}


}



@media only screen and (max-width: 1326px) 
{

  .arugot
  {
    text-align: center;
    width: 100%;
   height: 500px;
     background-color: #2c2b42;
  } 
  
  .map2
  {
    width: 100px;
    height: 100px;
  }


  .fontarugot
  {
    color: red;
    font-size: 20px;
  }

  .teampic {
    width: 300px; /* Set the width to 100% of the container's width */
    height: 300px; /* Let the height adjust automatically to maintain aspect ratio */
    
  }


  .fontth
{
  font-size: 15px;
}



@media only screen and ( max-width: 340px) 
{

  .line-container2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px; /* Adjust the desired spacing between elements */
    position: fixed;
    top: 0;
    left: 0;
    height: 80px;
    width: auto;
    padding: 0 20px; /* Adjust padding as needed */
  }

  .header {
  padding: 0px;
    text-align: center;
    background: #2c2b42; width: 100%; height: 300px;
    color: white;
    font-size: 30px;
    width: 100%;
  }

}


@media only screen and (max-width: 650px)
{
  input,
  textarea {
    width: 50%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid black; /* Updated border color to orange */
    border-radius: 5px;
    background-color: white; /* Updated background color to black */
    color: black; /* Set the text color to white */
   
  }
  
  button[type="submit"] {
    background-color: rgb(91, 190, 66); /* Set the background color to orange */
    color: black;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    width: 40%; /* Set the width to 100% */
    display: flex;
    justify-content: center;
  }


  .form-group {
    margin-bottom: 20px;
   text-align: center;
  }

.bform
{
  margin-left: auto;
  margin-right: auto;
}


}


@media only screen and ( max-width: 539px) 
{

  .team
  {
    width: 100%;
    height: 450px;
    margin: 0 auto;
    background-color:  #2c2b42;
    padding: 20px;
   /* background-color: white; /* Updated background color to black */
   
    border-radius: 5px;
   
  }


  .teampic2 {
    width: 200px; /* Set the width to 100% of the container's width */
    height: 200px; /* Let the height adjust automatically to maintain aspect ratio */
    
  }
  

}



@media only screen and ( max-width: 340px) 
{

  .team
  {
    width: 100%;
    height: 450px;
    margin: 0 auto;
    background-color:  #2c2b42;
    padding: 20px;
   /* background-color: white; /* Updated background color to black */
   
    border-radius: 5px;
   
  }


  .teampic2 {
    width: 150px; /* Set the width to 100% of the container's width */
    height: 150px; /* Let the height adjust automatically to maintain aspect ratio */
    
  }
  

}


}